<div class="sidebar-layout" [hidden]="!loading">
  <div class="sidebar-layout-content">
    <div class="sidebar-layout-inner-content">
      <ng-content select="[content]"></ng-content>
    </div>

    <div class="sidebar-layout-buttons">
      <div class="sidebar-layout-buttons-inner">
        <ng-content select="[buttons]"></ng-content>
      </div>
    </div>
  </div>
</div>

<div class="sidebar-layout-skeleton" [hidden]="loading">
  <ng-content select="[skeleton]"></ng-content>
</div>
